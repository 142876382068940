import * as yup from 'yup'

export const REGEXP_EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/

export const REGEXP_EMAIL_EDITED = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/

export const REGEXP_USERNAME_PATTERN = /^[a-zA-Z][a-zA-Z0-9]{0,127}$/ui

export const REGEXP_PASSWORD_PATTERN = /^.{4,}$/

export const REGEXP_OLD_PASSWORD_YUP_OBJECT = yup.string()
  .min(4, 'The password must not contain less than 4 characters')

export const REGEXP_PASSWORD_YUP_OBJECT = yup.string()
  .min(4, 'The password must not contain less than 4 characters').max(50)

export const REGEXP_NEW_PASSWORD_YUP_OBJECT = yup.string()
  .min(8, 'The password must be at least 8 characters long and contain at least one number and one letter')
  .test('has-digit-and-letter', 'The password must be at least 8 characters long and contain at least one number and one letter', (value) => /[0-9]/.test(value) && /[a-zA-Z]/.test(value))
// export const REGEXP_PASSWORD_YUP_OBJECT = yup.string()
//   .matches(REGEXP_PASSWORD_PATTERN, 'incorrect value')
//   .required('required field')
//   .max(50, 'The password must not contain more than 50 characters')
//   .test('has-special-char', 'The password must have at least one special character', (value) => /[!#$%&*+\/=?^_`{|}~]/.test(value || ''))
//   .test('has-digit', 'The password must have at least one digit (0-9)', (value) => /[0-9]/.test(value || ''))
//   .test('has-uppercase', 'The password must have at least one capital letter', (value) => /[A-Z]/.test(value || ''))
//   .min(8, 'The password must not contain less than 8 characters')

export const REGEXP_URL_PATTERN = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(\/[\/\d\w\.-]*)*(?:[\?])*(.+)*/gm
